import React from 'react';
import styled from '@emotion/styled';
import fonts from 'style/fonts';
import { mq } from 'style/variables';
import { contentWrapper } from 'style/mixin';
import Img from 'components/atoms/img';
import sizes from 'style/sizes';
import { TextSet } from 'components/molecules/textSet';
import { Outline } from 'components/molecules/outline';
import { Address } from 'components/molecules/address';
import colors from 'style/colors';
import FadeUpItem from 'components/organisms/fadeUpItem';
import { css } from '@emotion/core';

type StudioDetailMainProps = {
  imgpath: string | string[];
  alt: string;
  work?: string;
  country?: string;
  outlines?: Array<string>;
  address?: AddressObject;
  visitUrl?: string;
  type?: 'studio';
  isJa?: boolean; // JPのみ新しくスタイルの修正を加えたいため専用のbooleanでスタイル出し分け
};

type AddressObject = {
  address: string;
  mapUrl?: string;
  contactUrl: string;
};

const Container = styled.div<StudioDetailMainProps>`
  position: relative;
  margin-bottom: ${sizes.margin[120]};
  overflow: hidden;

  ${mq.onlysp} {
    margin-bottom: ${sizes.margin[64]};
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const TextAreaWrapper = styled.div<StudioDetailMainProps>`
  max-width: 100%;

  ${mq.onlypc} {
    overflow: hidden;
    margin-top: -100px;
  }
  ${mq.onlysp} {
    padding-top: 40px;
  }
`;

const TextArea = styled.div`
  position: relative;
  ${mq.onlypc} {
    display: flex;
    ${contentWrapper}
  }
  ${mq.onlysp} {
    padding: 0 ${sizes.margin[32]};
  }
`;

const TextAreaInner = styled.div`
  position: relative;
  background-color: ${colors.white01};
  z-index: 1;
  ${mq.onlypc} {
    width: ${(792 / 1440) * 100}%;
    padding-top: ${sizes.margin[100]};
    padding-right: ${sizes.margin[100]};
  }
  &::before {
    ${mq.onlysp} {
      display: none;
    }
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    background-color: ${colors.white01};
  }
`;

const RightContent = styled.div`
  position: relative;
  ${mq.onlypc} {
    width: ${(648 / 1440) * 100}%;
    margin-top: ${sizes.margin[100]};
  }
  ${mq.onlysp} {
    margin-top: ${sizes.margin[64]};
    padding: ${sizes.margin[24]} 0 ${sizes.margin[24]} ${sizes.margin[24]};
  }
  &::before {
    ${mq.onlysp} {
      content: '';
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${colors.gray03};
    }
  }
`;

const RightContentInner = styled.div`
  position: relative;
  &::before {
    ${mq.onlysp} {
      display: none;
    }
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${colors.gray03};
    width: 100vw;
    height: 100%;
  }
`;

const TextContent = styled.div`
  position: relative;
  height: 100%;
`;

const TextSetWrapper = styled(TextSet)`
  margin-bottom: 0;
  ${mq.onlysp} {
    margin-bottom: 0;
  }
`;

const Country = styled.div<Pick<StudioDetailMainProps, 'isJa'>>`
  font-family: ${fonts.enGothic};
  margin-bottom: ${sizes.margin[32]};
  font-size: 16px;
  font-weight: 600;
  line-height: 1em;

  ${({ isJa }) =>
    isJa
      ? css`
          font-family: ${fonts.jaGothic};
          ${mq.onlysp} {
            font-size: 14px;
          }
        `
      : null}
`;

export const StudioDetailMain: React.FC<StudioDetailMainProps> = props => {
  return (
    <Container {...props}>
      <ImageWrapper>
        <Img filename={props.imgpath} alt={props.alt} />
      </ImageWrapper>

      <TextAreaWrapper {...props}>
        <TextArea>
          <TextAreaInner>
            <FadeUpItem delay={1.5}>
              <TextContent>
                {props.country && (
                  <Country isJa={props.isJa}>
                    <span>{props.country}</span>
                  </Country>
                )}
                <TextSetWrapper>{props.children}</TextSetWrapper>
              </TextContent>
            </FadeUpItem>
          </TextAreaInner>

          <RightContent>
            <RightContentInner>
              <FadeUpItem delay={1.7}>
                {props.outlines && <Outline outlines={props.outlines} visitUrl={props.visitUrl} />}
                {props.address && (
                  <Address
                    address={props.address.address}
                    mapUrl={props.address.mapUrl}
                    contactUrl={props.address.contactUrl}
                  />
                )}
              </FadeUpItem>
            </RightContentInner>
          </RightContent>
        </TextArea>
      </TextAreaWrapper>
    </Container>
  );
};
