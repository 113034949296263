import styled from '@emotion/styled';
import { Button } from 'components/atoms/button';
import { EnHeading } from 'components/atoms/enHeading';
import React from 'react';
import colors from 'style/colors';
import fonts from 'style/fonts';
import { text } from 'style/mixin';
import sizes from 'style/sizes';
import { mq } from 'style/variables';

export type SnsProps = {
  url: string;
  type: 'facebook' | 'note' | 'wantedly' | 'dribble' | 'behance' | 'linkedin' | 'instagram';
};

type AddressProps = {
  address: string;
  mapUrl?: string;
  contactUrl?: string;
};

const Container = styled.div`
  display: block;
  position: relative;
  ${mq.onlypc} {
    padding: ${sizes.margin[48]};
    background-color: ${colors.gray03};
  }
`;

const Title = styled.div`
  margin-bottom: ${sizes.margin[32]};
  ${mq.onlysp} {
    margin-bottom: ${sizes.margin[24]};
  }
`;

const Addresss = styled.div`
  font-family: ${fonts.enGothic};
  ${text(12, 2)}
  letter-spacing: ${0.91 / 12}em;
`;

const Btns = styled.div`
  ${mq.onlypc} {
    display: flex;
    justify-content: flex-start;
    margin-top: 32px;
  }
  ${mq.onlysp} {
    margin-top: 24px;
  }
  > a + a {
    ${mq.onlypc} {
      margin-left: 8px;
    }
    ${mq.onlysp} {
      margin-top: 8px;
    }
  }
  > a {
    ${mq.onlysp} {
      width: 100%;
      height: 48px;
      font-size: 15px;
    }
  }
`;

export const Address: React.FC<AddressProps> = props => {
  return (
    <Container>
      <Title>
        <EnHeading size={'xsmall'}>Address</EnHeading>
      </Title>
      <Addresss>{props.address}</Addresss>
      <Btns>
        {props.mapUrl && (
          <Button href={props.mapUrl} blank>
            MAP
          </Button>
        )}
        {props.contactUrl && (
          <Button type={'fill'} href={props.contactUrl} blank>
            Contact
          </Button>
        )}
      </Btns>
    </Container>
  );
};
